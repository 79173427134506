@import "components/card/card.css";
@import "components/search-bar/search-bar.css";
@import "components/nav-button/nav-button.css";
@import "components/nav-pagination/nav-pagination.css";
@import "node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "node_modules/animate.css/animate.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  /* Color Variables */

  --color-nemo: #ff4a11;
  --color-granite: #252629;
  --color-water-10: #f3f5f9;
  --color-foam: #ffffff;
  --color-btn-disabled: #a5a5a5;
}

body {
  margin: 0;
  font-family: system-ui;
  height: 100vh;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

dt,
dl,
dd {
  padding: 0;
  margin: 0;
}

h2 {
  margin: 0;
}

.title {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  margin: 0;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

main {
  overflow-y: auto;
  padding: 0;
}

.card-container {
  display: grid;
  gap: 2rem;
  padding: 20px;
  place-content: center;
}

.navigation {
  display: grid;
  grid-template-columns: 1fr 90px 1fr;
  width: 100%;
  height: 60px;
  gap: 1px;
  background-color: #cccccc;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 3px, rgba(0, 0, 0, 0.24) 0px -1px 2px;
  position: fixed;
  bottom: 0;
}
