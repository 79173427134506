.button {
  background-color: var(--color-water-10);
  border: none;
  color: var(--color-granite);
  font-size: 1.2rem;
  transition: 0.3s ease;
}

.button:hover {
  background-color: var(--color-nemo);
  cursor: pointer;
}

.button--disabled {
  color: var(--color-btn-disabled);
}

.button--disabled:hover {
  background-color: var(--color-water-10);
  cursor: default;
}
